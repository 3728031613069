<template>
  <div class="section-content section-commercial pt-4 pb-5 w-100 container">
    <b-container class="container-xlg m-auto pl-xxl-0 pr-xxl-0">
      <div class="top-event d-flex flex-column flex-md-column">
        <esports-title
          title="Company"
          class="pb-2 pt-2 mb-2 mt-2 mt-xxl-4 pb-xxl-5 article-page"
          sub_title="運営会社"
        />
      </div>
      <div class="term-content mt-2 mb-0 mb-lg-5">
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">会社名</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0">株式会社メルメクス</h4></b-col>
          </b-row>
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">住所</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0">150-0002　東京都渋谷区渋谷3-10-15</h4></b-col>
          </b-row>
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">設立</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0">2005年4月</h4></b-col>
          </b-row>
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">資本金</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0">20,000,000円</h4></b-col>
          </b-row>
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">事業内容</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0">Donate + eSportsの運営</h4></b-col>
          </b-row>
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">代表者</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0">森雄作</h4></b-col>
          </b-row>
          <b-row align-v="stretch" class="mx-md-0 flex-column flex-lg-row pb-2 pb-lg-0">
                <b-col class="title py-3" lg="4"><h4 class="mb-0 ml-lg-2">連絡先</h4></b-col>
                <b-col class="content py-3 ml-md-1"><h4 class="ml-lg-3 mb-0"><b-link href="mailto:info@melmax.co.jp" target="_blank">info@melmax.co.jp</b-link></h4></b-col>
          </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
    name: "SectionCompany"
}
</script>